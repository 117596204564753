<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <h3>
        {{ $t('payment.pastPayments.tabs.matchedPayments') }}
      </h3>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        show-index
        border
        rounded
        class="mb-4"
        @row-click="handleRowClick"
      >
        <template #cell-index="{ rowIndex }">
          {{ rowIndex + 1 + pageSize * (currentPage - 1) }}
        </template>
        <template #cell-paymentDate="{ rowData: { paymentDate } }">
          <p>{{ formatDate(paymentDate) }}</p>
        </template>
        <template #cell-paidAmount="{ rowData: { paidAmount } }">
          <p>{{ formatMoney(paidAmount) }}</p>
        </template>
        <template #cell-approvedAt="{ rowData: { approvedAt } }">
          <p>{{ approvedAt ? formatDateTime(approvedAt) : '-' }}</p>
        </template>
      </Table>
    </div>
    <el-pagination
      v-if="totalCount > pageSize"
      :current-page.sync="currentPage"
      small
      layout="prev, pager, next, jumper"
      background
      :page-size="pageSize"
      :total="totalCount"
      class="d-flex justify-content-end p-0"
    />
    <EventMapModal v-if="activity" :activity="activity" @close="activity = null" />
  </div>
</template>

<script>
import { isNil } from 'ramda';
import { ref, computed } from 'vue';
import { DateTime } from 'luxon';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useSuppliersNew } from '@/modules/suppliers';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { usePaymentDelete } from '@/modules/eventMapModal/compositions/payment';

import { usePayments } from './pastPayments/compositions/payment';
import { formatDate, formatDateTime } from '../../tools/formatters';

const TABLE_HEADER = {
  PAYMENT_DATE: 'paymentDate',
  PAYMENT_METHOD: 'paymentMethod',
  SUPPLIER: 'supplier',
  INVOICE_COUNT: 'invoiceCount',
  INVOICES_DATE: 'invoicesDate',
  PAID_AMOUNT: 'paidAmount',
  ACTION: 'action',
  APPROVED_BY: 'approvedBy',
  APPROVED_AT: 'approvedAt',
};

const PAYMENTS_DEFAULT_LIMIT = 50;

export default {
  components: { Table, EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'), TableLoadingSkeleton },
  props: {
    customer: { type: Object, required: true },
    currentSupplier: { type: String, default: null },
  },
  setup(props) {
    const { onDone: onPaymentDeleteDone } = usePaymentDelete();
    const { suppliers, loading: suppliersLoading, refetch: refetchSuppliers } = useSuppliersNew();
    const currentPage = ref(1);
    const { formatToCurrency } = useCurrency();

    const pageSize = PAYMENTS_DEFAULT_LIMIT;
    const {
      payments,
      totalCount,
      loading: paymentsLoading,
      refetch: refetchPayments,
    } = usePayments(
      () => ({
        completed: true,
        limit: pageSize,
        offset: (currentPage.value - 1) * pageSize,
        supplierId: props.currentSupplier,
        businessId: props.customer.id,
      }),
      () => ({ enabled: Boolean(props.customer.id) })
    );

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    onPaymentDeleteDone(() => {
      refetchPayments();
    });

    return {
      suppliers,
      refetchSuppliers,
      pageSize,
      loading: computed(() => suppliersLoading.value || paymentsLoading.value),
      currentPage,
      payments,
      refetchPayments,
      totalCount,
      formatMoney,
      formatDateTime,
      activity: ref(),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_DATE}`),
          key: TABLE_HEADER.PAYMENT_DATE,
          width: '100px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.SUPPLIER}`),
          key: TABLE_HEADER.SUPPLIER,
          width: '150px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.INVOICE_COUNT}`),
          key: TABLE_HEADER.INVOICE_COUNT,
          width: '80px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.INVOICES_DATE}`),
          key: TABLE_HEADER.INVOICES_DATE,
          width: '110px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAID_AMOUNT}`),
          key: TABLE_HEADER.PAID_AMOUNT,
          width: '120px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
          width: '130px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.ACTION}`),
          key: TABLE_HEADER.ACTION,
          width: '110px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.APPROVED_BY}`),
          key: TABLE_HEADER.APPROVED_BY,
          width: '110px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.APPROVED_AT}`),
          key: TABLE_HEADER.APPROVED_AT,
          width: '190px',
        },
      ];
    },
    tableData() {
      return this.payments.map(
        ({
          id,
          supplierId,
          date,
          billingLinks,
          amount,
          isRefund,
          createdBy,
          createdAt,
          transactions,
          type,
          initialBalance,
        }) => {
          const supplier = this.suppliers.find(({ id }) => id === supplierId);

          if (!supplier) this.refetchSuppliers();

          let paymentMethod;
          if (transactions.length) {
            paymentMethod = `${this.$t(`payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`)}${
              transactions[0].directDebit ? ` - ${this.translate('directDebit')}` : ''
            }`;
          } else {
            if (!initialBalance) {
              paymentMethod = '-';
            } else {
              paymentMethod = this.translate(`noPaymentMethod`);
            }
          }

          const actionText = () => {
            if (type === 'balance') {
              if (initialBalance) {
                return this.translate(`table.${type}`);
              } else {
                return '-';
              }
            }

            return this.translate(`table.${type}`);
          };

          return {
            id,
            [TABLE_HEADER.PAYMENT_DATE]: date,
            [TABLE_HEADER.PAYMENT_METHOD]: paymentMethod,
            [TABLE_HEADER.SUPPLIER]: supplier?.name ?? '-',
            [TABLE_HEADER.INVOICE_COUNT]: billingLinks.length,
            [TABLE_HEADER.INVOICES_DATE]: this.formatBilledPastPaymentDate(billingLinks.map(({ billing }) => billing)),
            [TABLE_HEADER.PAID_AMOUNT]: amount * (isRefund ? -1 : 1),
            [TABLE_HEADER.ACTION]: actionText(),
            [TABLE_HEADER.APPROVED_BY]: createdBy
              ? `${createdBy.profile.firstName} ${createdBy.profile.lastName}`
              : '-',
            [TABLE_HEADER.APPROVED_AT]: createdBy && new Date(createdAt),
          };
        }
      );
    },
  },
  methods: {
    translate(key) {
      return this.$t(`payment.pastPayments.${key}`);
    },
    formatDate,
    formatBilledPastPaymentDate(billedWithDate, returnDateObject = false) {
      if (!billedWithDate.length || billedWithDate.some(isNil)) return '-';

      if (billedWithDate.length === 1)
        return returnDateObject ? new Date(billedWithDate[0].date) : formatDate(billedWithDate[0].date);

      const dateCount = billedWithDate.reduce((isoMonthGroupsCount, { date: billedDate }) => {
        const isoMonthDate = DateTime.fromJSDate(new Date(billedDate)).toUTC().toFormat('yyyy-LL');
        if (!isoMonthGroupsCount[isoMonthDate]) isoMonthGroupsCount[isoMonthDate] = 0;
        isoMonthGroupsCount[isoMonthDate] += 1;

        return isoMonthGroupsCount;
      }, {});

      let maxDate = null;
      Object.entries(dateCount).forEach(([isoMonthDate, count]) => {
        if (!maxDate) maxDate = isoMonthDate;
        if (count > dateCount[maxDate]) maxDate = isoMonthDate;
      });

      if (returnDateObject) return new Date(maxDate);

      return new Date(maxDate).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
        timeZone: 'UTC',
      });
    },
    handleRowClick(index) {
      this.activity = {
        type: 'payment',
        id: this.tableData[index].id,
      };
    },
  },
};
</script>
