<template>
  <el-dialog
    width="507px"
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded partial-payment-modal"
    @close="close"
  >
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h2 class="text-typography-primary">
            {{ $t('payment.billingsToPaySelectionModal.partialPaymentModal.title', { documentTypeAndNumber }) }}
          </h2>
          <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
            <CloseIcon width="24" height="24" />
          </Button>
        </div>
        <p>
          {{
            `${$t('payment.billingsToPaySelectionModal.partialPaymentModal.billingTotal', {
              amount: formatMoney(billing.totalAmount / 100),
            })}, ${$t('payment.billingsToPaySelectionModal.partialPaymentModal.remainderAmount', {
              amount: formatMoney(billing.paymentData.remainderAmount),
            })}`
          }}
        </p>
      </div>
    </template>
    <div class="py-5 px-4">
      <div class="pt-2">
        <el-form ref="form" :model="formModel" :show-message="false" @submit.native.prevent>
          <p class="fw-bold mb-4">
            {{ $t('payment.billingsToPaySelectionModal.partialPaymentModal.amountToPay') }}
          </p>
          <el-form-item prop="amount" class="col pt-1" :rules="validationRules">
            <el-input v-model="formModel.amount" size="small" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button size="medium" type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button size="medium" @click="submit">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  components: { Button, CloseIcon },
  props: { billing: { type: Object, required: true } },
  setup() {
    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return { formatMoney };
  },
  data() {
    return {
      formModel: {},
      validationRules: [
        {
          required: true,
          type: 'string',
          pattern: /\d+/,
        },
        {
          validator: (_, value, callback) => {
            if (
              Math.sign(value) !== Math.sign(this.billing.paymentData.remainderAmount) ||
              Math.abs(value) > Math.abs(this.billing.paymentData.remainderAmount)
            )
              callback(new Error());
            callback();
          },
        },
      ],
    };
  },
  computed: {
    documentTypeAndNumber() {
      const documentSourceType = this.billing.eventReferences[0].document?.type;
      const documentNumber = this.billing.eventReferences[0].document?.documentNumber;
      return documentSourceType
        ? `${this.$t(`document.exports.schema.type.shortName.${documentSourceType}`)} ${this.$t(
            'document.exports.schema.fields.documentNumber'
          )} ${documentNumber}`
        : null;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit('partial-payment', {
            billingId: this.billing.id,
            amount: parseFloat(this.formModel.amount),
            date: this.billing.date,
          });
        }
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .partial-payment-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

::v-deep .el-radio__input {
  padding-top: 3px;
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

.actions-btn {
  cursor: pointer;
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}

::v-deep .el-form-item__content {
  line-height: 1.5;
}
</style>
